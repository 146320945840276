export const sayHello = ():void => {
	console.log('Hello from share utils');
};


export function imgLimit (url: string, size = 126) {
	try {
		// url = url.replace(/(\?\d+)/g, '');
		const hasP = url.indexOf('?') > -1;
		return `${url}${hasP ? '&' : '?'}x-image-process=image/resize,w_${size},limit_0`;
	} catch (error) {
		return url;
	}
}

// 复制文本
export function copyText (text: string) {
	return new Promise((resolve, reject) => {
		try {
			const input = document.createElement('textarea');
			document.body.appendChild(input);
			input.value = text;
			input.select();
			if (document.execCommand('copy')) {
				document.execCommand('copy');
			}
			document.body.removeChild(input);
			resolve(text);
		} catch (error) {
			reject(error);
		}
	});
}

/**
 * 可配置防抖函数
 * @param  {function} func        回调函数
 * @param  {number}   wait        表示时间窗口的间隔
 * @param  {boolean}  immediate   设置为ture时，是否立即调用函数
 * @return {function}             返回客户调用函数
 */
 export function debounce (func: any, wait = 50, immediate = false) {
	let timer:number | null, context: any, args: any;
	// 延迟执行函数
	const later = () => setTimeout(() => {
		// 延迟函数执行完毕，清空缓存的定时器序号
		timer = null;
		// 延迟执行的情况下，函数会在延迟函数中执行
		// 使用到之前缓存的参数和上下文
		if (!immediate) {
			func.apply(context, args);
			context = args = null;
		}
	}, wait);

	// 这里返回的函数是每次实际调用的函数
	return function (...params:any[]) {
		// 如果没有创建延迟执行函数（later），就创建一个
		if (!timer) {
			later();
			// 如果是立即执行，调用函数
			// 否则缓存参数和调用上下文
			if (immediate) {
				// @ts-ignore
				func.apply(this, params);
			} else {
				// @ts-ignore
				context = this;
				args = params;
			}
			// 如果已有延迟执行函数（later），调用的时候清除原来的并重新设定一个
			// 这样做延迟函数会重新计时
		} else {
			clearTimeout(timer);
			later();
		}
	};
}

// 数组对象根据某属性排序
export const compare = (property: string) => {
	return function (a: any, b: any) {
		let valueA = a[property];
		let valueB = b[property];
		return valueA - valueB;
	};
};

// 判断两个对象的值是否相等
export function compareArrays(arr1:any, arr2:any) {
  // 假设两个数组具有相同的长度
  const length = arr1.length;
	let arr: any = [];
  // 遍历数组
  for (let i = 0; i < length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    // 检查键值对是否相同
    if (obj1.value !== obj2.value && obj2.value) {
      arr.push(obj1.name);
    }
  }
	return arr;
}

export function compareArraysGroup(arr1:any, arr2:any) {
  // 假设两个数组具有相同的长度
  const length = arr1.length;
	let arr: any = [];
  // 遍历数组
  for (let i = 0; i < length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];
		for (const key in obj1.enable) {
			if (obj1.enable[key] !== obj2.enable[key]) {
				arr.push(obj1.enable.name);
			}
		}
		for (let j = 0; j < obj1.params.length; j++) {
			if (obj1.params[j].value !== obj2.params[j].value) {
				arr.push(obj1.params[j].name);
			}
		}

  }
	return arr;
}

//格式 gid=1000&callbackUrl=%7B%
export const strToJson = (queryString: string) => {
	// 创建 URLSearchParams 对象并解析查询字符串
	const params = new URLSearchParams(queryString);

	// 创建空对象，用于存放转换后的 JSON 数据
	const jsonData:any = {};

	// 遍历 URLSearchParams 对象，解码值并添加到 jsonData 中
	for (const [key, value] of params) {
		// 如果值是 JSON 格式的字符串，则解码后存储为对象
		if (value.startsWith('{') && value.endsWith('}')) {
			jsonData[key] = JSON.parse(decodeURIComponent(value));
		} else {
			jsonData[key] = decodeURIComponent(value);
		}
	}

	return jsonData;
};
