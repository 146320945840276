import { defineStore } from 'pinia';
import { apiLogin, apiLogout, apiGetUserInfo } from '@/api/user';
import { Role, usePermissionStore } from './permission';
import { useAppStore } from './app';

export interface UserInfo {
	userInfo: any,
	permissions: any
	roles: any[]
	uid: number
	token: string
}

export const useUserStore = defineStore('user', {
	state: (): UserInfo => ({
		userInfo: {},
    permissions: [],
    roles: [],
    uid: 0,
		token: ''
	}),
	actions: {
		setUserInfo (userInfo: any) {
			this.userInfo = userInfo;
		},
		async login (params: any) {
			const res = await apiLogin(params);
			if (res.code === 0) {
				this.setUserInfo(res.data);
        this.token = res.data.Token;
        this.uid = res.data.Id;
				localStorage.setItem('user', JSON.stringify({
					userInfo: res.data,
					token: res.data.Token,
					uid: res.data.Id
				}));
				return Promise.resolve(res);
			} else {
        return Promise.reject(res);
      }
		},
		async logout () {
			const res = await apiLogout();

			if ([0, 14].includes(res.code)) {
				this.clearCache();
				return Promise.resolve(res);
			} else {
				return Promise.reject(res);
			}
		},
		async getUserInfo (type?: string) {
			if (type !== 'reload' && this.userInfo.Id) {
				return Promise.resolve(this.userInfo);
			}
			const res = await apiGetUserInfo();
			if (res.code === 0) {
				this.setUserInfo(res.data);
				return res.data;
			} else {
				return Promise.reject(res);
			}
		},
		getRole (): Promise<Role[]> {
			return new Promise((resolve, reject) => {
				if (this.userInfo.Id) {
					let permissions = this.userInfo.Permissions;
					this.roles = [...permissions];
					if (this.roles.length > 0) {
						resolve(this.roles);
					} else {
						reject({ msg: '用户没有分配权限!' });
					}
				} else {
					reject({ msg: '用户没有分配权限!' });
				}
			});
		},
		clearCache () {
			const { setGameInfo } = useAppStore();
			const { clearPermission } = usePermissionStore();
			this.token = '';
			this.uid = 0;
			this.userInfo = {};
			localStorage.removeItem('user');
			setGameInfo({});
			clearPermission();
			sessionStorage.removeItem('app');
		}
	},
	persist: {
		enabled: true,
		strategies: [
			{
				storage: localStorage,
				paths: ['token', 'uid', 'userInfo']
			}
		]
	}
});
