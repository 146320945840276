const ObsClient = require('./esdk-obs-browserjs-3.19.5.min.js'); 

const isProduction = process.env.NODE_ENV !== 'development';

const BUCKET_LIST = JSON.parse(process.env.VUE_APP_HW_BUCKET).data;

const HuaweiCloud = {
  access_key_id: process.env.VUE_APP_HW_ACCESS_KEY_ID,
  secret_access_key: process.env.VUE_APP_HW_SECRET_ACCESS_KEY,
  server: process.env.VUE_APP_HW_SERVER
};

const Buckt = {};
for (let i = 0; i < BUCKET_LIST.length; i++) {
  Buckt[BUCKET_LIST[i].name] = BUCKET_LIST[i].value;
}

/**
 * 上传文件，分片上传，上传文本
 * @param {*} params
 * @param {Number} type 1: 普通上传，2: 切片上传，3，上传文本
 * @param {Boolean} isPrivate 是否上传到私有桶
 * @returns
 */
export default function ultraUploadFile ({ params, type = 1, isPrivate = false, textType = 'txt' }) {
  return new Promise((resolve, reject) => {
    params.obsClientData = HuaweiCloud;
    if (BUCKET_LIST.length === 1) {
      if (isPrivate) {
        params.bucketName = BUCKET_LIST[0].name;
      } else if (type === 1 || type === 2) {
        params.bucketName = BUCKET_LIST[0].name;
      } else if (type === 3) {
        params.bucketName = BUCKET_LIST[0].name;
      }
    } else {
      if (isPrivate) {
        params.bucketName = BUCKET_LIST[0].name;
      } else if (type === 1 || type === 2) {
        params.bucketName = BUCKET_LIST[1].name;
      } else if (type === 3) {
        params.bucketName = BUCKET_LIST[2].name;
      }
    }
   
    if (type === 3) {
      // 上传文本
      const objectKey = isProduction ? `${params.gid}/${params.cid}/agr.${textType}` : `test/${params.gid}/${params.cid}/agr.${textType}`;
      const obsClient = new ObsClient(params.obsClientData);

      obsClient.putObject({
        Bucket: BUCKET_LIST[0].name,
        Key: objectKey,
        Body: params.text
      }, function (err, result) {
        if (!err && result.CommonMsg.Status < 300) {
          // console.log('上传成功', result);
          result.url = Buckt[params.bucketName] + objectKey;
          resolve(result);
        } else {
          let msg = err || result.CommonMsg.Code;
          // 回调错误
          reject(msg);
        }
      });
    } else {
      params.objectKey = 'file/';
      params.objectKey += new Date().getTime() + '_' + parseInt(Math.random() * 100000000);
      params.objectKey += fnGetExtension(params.file)[0];
      if (type === 2) {
        params.cancelUpload = false;
      }
      console.log(fnGetExtension(params.file));
      if (params.key) {
        params.objectKey = params.key;
      }
      if (!params.file) {
        let errMsg = {
          msg: '请选择文件'
        };
        reject(errMsg);
        return false;
      }
      if (params.accept && !params.accept.includes(fnGetExtension(params.file)[1])) {
        let errMsg = {
          msg: `请上传符合以下格式的文件：${params.accept}`
        };
        reject(errMsg);
        return false;
      }
      params.obsClient = new ObsClient(params.obsClientData); // eslint-disable-line
      if (type === 1) {
        // 普通上传
        const callback = (transferredAmount, totalAmount, totalSeconds) => {
          let percent = parseInt(transferredAmount * 100.0 / totalAmount);
          params.progressCb && params.progressCb(percent, {
            transferredAmount,
            totalAmount,
            totalSeconds
          });
        };
        params.obsClient.putObject({
          Bucket: params.bucketName,
          Key: params.objectKey,
          SourceFile: params.file,
          ProgressCallback: callback
        }, function (err, result) {
          if (!err && result.CommonMsg.Status < 300) {
            console.log('上传成功', result);
            result.key = params.objectKey;
            result.url = Buckt[params.bucketName] + params.objectKey;
            resolve(result);
          } else {
            let msg = err || result.CommonMsg.Code;
            // 回调错误
            reject(msg);
          }
        });
      } else if (type === 2) {
        // 切片上传
        let fileSize = params.file.size;
        let partSize = 1024 * 1000;
        params.obsClient.initiateMultipartUpload({
          Bucket: params.bucketName,
          Key: params.objectKey
          // Expires: params.expires,
        }).then(function (result) {
          let uploadId = result.InterfaceResult.UploadId;
          let partCount = fileSize % partSize === 0 ? Math.floor(fileSize / partSize) : Math.floor(fileSize / partSize) + 1;
          let uploadPartStatus = {
            bucketName: params.bucketName,
            objectKey: params.objectKey,
            uploadId: uploadId,
            file: params.file,
            fileSize: fileSize,
            partSize: partSize,
            partCount: partCount,
            currentPartIndex: 0,
            parts: []
          };
          doUploadPart(params.obsClient, uploadPartStatus);
        }).catch(function (err) {
          console.error('err:' + err);
        });
        let globalContext = {};
        globalContext.maxTaskNum = 5;
        globalContext.currentTaskNum = 0;
        // 回调进度
        const progress = (data, result) => {
          // console.log(data);
          if (result && result == 'end') {
            params.progressCb && params.progressCb(100);
            return;
          }
          // 当前切片
          let currentPartIndex = data.currentPartIndex;
          // 文件大小
          let fileSize = data.fileSize;
          // 分段大小
          let partSize = data.partSize;
          // 已上传大小
          let uploadSize = partSize * currentPartIndex;
          if (uploadSize > fileSize) uploadSize = fileSize;
          // 百分比进度
          var percent = (uploadSize / fileSize * 100).toFixed(2);
          if (!params.cancelUpload) { params.progressCb && params.progressCb(percent); }
          params.uploadingCb && params.uploadingCb(function (cbParams) {
            if (cbParams && cbParams.cancelUpload) {
              params.cancelUpload = true;
            }
          });
        };
        // 切片
        const doUploadPart = (obsClient, uploadPartStatus) => {
          while (uploadPartStatus.currentPartIndex < uploadPartStatus.partCount && globalContext.currentTaskNum < globalContext.maxTaskNum) {
            let offset = uploadPartStatus.currentPartIndex * uploadPartStatus.partSize;
            let currPartSize = (uploadPartStatus.currentPartIndex + 1 === uploadPartStatus.partCount) ? uploadPartStatus.fileSize - offset : uploadPartStatus.partSize;
            let partNumber = uploadPartStatus.currentPartIndex + 1;
            globalContext.currentTaskNum++;
            uploadPartStatus.currentPartIndex++;
            obsClient.uploadPart({
              Bucket: uploadPartStatus.bucketName,
              Key: uploadPartStatus.objectKey,
              PartNumber: partNumber,
              UploadId: uploadPartStatus.uploadId,
              SourceFile: uploadPartStatus.file,
              Offset: offset,
              PartSize: currPartSize
            }).then(function (result) {
              globalContext.currentTaskNum--;
              // 取消上传
              if (params.cancelUpload) return false;
              doUploadPart(obsClient, uploadPartStatus);
              if (result.CommonMsg.Status < 300) {
                // 上传进度；
                progress(uploadPartStatus);
                uploadPartStatus.parts.push({
                  PartNumber: partNumber,
                  ETag: result.InterfaceResult.ETag
                });
                if (uploadPartStatus.parts.length === uploadPartStatus.partCount) {
                  let _parts = uploadPartStatus.parts.sort(function (a, b) {
                    if (a.PartNumber >= b.PartNumber) {
                      return 1;
                    }
                    return -1;
                  });
                  obsClient.completeMultipartUpload({
                    Bucket: uploadPartStatus.bucketName,
                    Key: uploadPartStatus.objectKey,
                    UploadId: uploadPartStatus.uploadId,
                    Parts: _parts
                  }, function (err, result) {
                    console.log(err, result);
                    if (!err && result.CommonMsg.Status < 300) {
                      progress(uploadPartStatus, 'end');
                      console.log('上传成功');
                      result.key = params.objectKey;
                      result.url = Buckt[params.bucketName] + params.objectKey;
                      resolve(result);
                    } else {
                      var msg = err || result.CommonMsg.Code;
                      console.error(msg);
                      // 回调错误
                      params.error && params.error(msg);
                    }
                  });
                }
              } else {
                console.error(result.CommonMsg.Code);
                // 回调错误
                reject(result);
              }
            }).catch(function (err) {
              console.error('err:' + err);
              // 回调错误
              params.error && params.error(err);
            });
          }
        };
      }
    }
  });
}

function fnGetExtension (file) {
  var fileName = file.name;
  var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  var fileExtension = (fileName).match(patternFileExtension);
  return fileExtension;
}
