export default (Vue: any, getBtnPermissions: () => any) => {
  /**自定义按钮权限指令 */
  Vue.directive('has', {
    mounted(el:HTMLElement, binding: any) {
      // console.log(el, binding);
      //获取按钮权限
      if (!Vue.config.globalProperties.$has(binding.value)) {
        //移除不匹配的按钮
        el.parentNode!.removeChild(el);
      }
    },
  });

  //检查权限方法
  Vue.config.globalProperties.$has = function (value:string|string[]) {
    let isExist = false;
    if (!value) {
      return true;
    }
    let { btnPermissions } = getBtnPermissions();
    if (!btnPermissions && btnPermissions.length) {
      return false;
    }
    if (Array.isArray(value)) {
      isExist = value.some((item) => {
        return btnPermissions.includes(item);
      });
    } else {
      isExist = btnPermissions.includes(value);
    }
    return isExist;
  };
};