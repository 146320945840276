import { apiUsdkGetGameChannel } from '@/api/usdk';
export const UseGetGameChannel = () => {
  /**
  * 获取游戏下的渠道
  * @param {*} gid 游戏id
  * @param {*} name 渠道名称
  * @param {*} active 激活状态(空:所有,1:激活中,0:禁用)
  * @param {*} isConf 是否完成配置(空:所有,1:配置完成,0:未配置完成)
  * @param {*} isImage 是否镜像渠道(空:所有,1:是,0:否)
  * @param {*} isHero 是否官方渠道(空:所有,1:是,0:否)`
  * @param {*} needIos 是否加载ios(默认1:是,0:否)`
  * @param {*} isGlobal 是否只加载全球(默认1:是,0:否)`
  */
  const getGameChannel = ({
    gid,
    name,
    active = 1,
    isConf,
    isImage,
    isHero,
    needIos,
    isGlobal
  }) => {
    return new Promise((resolve, reject) => {
      apiUsdkGetGameChannel({
        gid,
        name,
        active,
        isConf,
        isImage,
        isHero,
        needIos,
        isGlobal
      }).then(res => {
        if (res.code === 0) {
          resolve(res.data);
        }
      }).catch(err => {
        reject(err);
      });
    });
  };
  return getGameChannel;
};
// 获取全局配置，在 setup 语法糖中使用
export const UseGlobalConfig = () => {
  const {
    appContext: {
      config: {
        globalProperties: {
          $filters,
          $channel,
          $has
        }
      }
    }
  } = getCurrentInstance();
  return {
    $filters,
    $channel,
    $has
  };
};