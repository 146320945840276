import req from '../utils/request';

export function internalComAxios (path: string, params: any, errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/app/send',
		method: 'post',
		appid: 1,
		path: '/msdk/send',
		data: Object.assign(params || {}, { path }),
		errNotice: errNotice,
		successNotice: successNotice
	});
}

export function globalComAxios (path: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/app/send',
		method: 'post',
		appid: 1,
		path: '/hgsdk/send',
		data: Object.assign(params, { path }),
		errNotice: errNotice,
		successNotice: successNotice
	});
}

export function globalWithOutGidComAxios (path: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/app/send',
		method: 'post',
		appid: 1,
		path: '/hgsdk/sendWithOutGid',
		data: Object.assign(params, { path }),
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// 国内usdkJson
export function internalUsdkJsonComAxios (path: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/protected/app/send/usdk/msdk/sendWithJson',
		method: 'post',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		},
		appid: 1,
		data: Object.assign(params, { path }),
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// 分享数据中心
export function dataCenterComAxios (path: string, params = {},errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/protected/app/send/data-new' + path,
		method: 'post',
		appid: 9,
		path,
		data: params,
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// 分享数据中心
export function dataCenterComAxiosGet (path: string, params = {},errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/protected/app/send/data-new' + path,
		method: 'get',
		appid: 9,
		path,
		params,
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// 中台
export function sampleAxios (path: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
    url: path,
		method: 'post',
		data: params,
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// json格式数据请求
export const JSON_GET = function (url: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url,
		method: 'get',
		params,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		},
		errNotice,
		successNotice
	});
};

export const JSON_PUT = function (url: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url,
		method: 'put',
		data: params,
		errNotice,
		successNotice
	});
};

export const JSON_POST = function (url: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url,
		method: 'post',
		data: params,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		},
		errNotice,
		successNotice
	});
};

export const JSON_DEL = function (url: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url,
		method: 'delete',
		data: params,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		},
		errNotice,
		successNotice
	});
};

