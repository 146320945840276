import { defineStore } from 'pinia';

import { constantRoutes, asyncRoutes } from '@/router';
import { RouteRecordRaw } from 'vue-router';
import router, { errRoute } from '@/router';

export interface Role {
  key: string;
  title: string;
  type: string;
  path?: string;
  children?: Role[];
	is_pay?: number
}
function hasPermission (permission:string[], route:RouteRecordRaw): boolean {
	let flag = false;
  flag = permission.includes(route.path);
  return flag;
}

function filterAsyncRouter (routerMap:RouteRecordRaw[], roles:string[]): RouteRecordRaw[] {
	return routerMap.filter(route => {
		if (hasPermission(roles, route)) {
			return true;
		}
		return false;
	}).map(route => {
		route = Object.assign({}, route);
		if (route.children) {
			route.children = filterAsyncRouter(route.children, roles);
		}
		return route;
	});
}
export const usePermissionStore = defineStore('permission', {
	state: () => ({
		btnPermissions: <string[]>[],
    permitRoutes: constantRoutes,
    hasErrorRoute: false
	}),
	actions: {
		setBtnPermission (permission: string[]) {
      this.btnPermissions = permission;
    },
    setPermitRoues (rotes:RouteRecordRaw[] ) {
      console.log('rotes: ', rotes);
      
      this.permitRoutes = [...constantRoutes, ...rotes];
    },
    generateRoutes (permissions: Role[]):Promise<RouteRecordRaw[]> {
      return new Promise((resolve, reject) => {
        let menu:string[] = [];
        let btn:string[] = [];
        permissions.forEach(item => {
          if (item.type === 'page') {
            menu.push(item.path!);
          } else if (item.type === 'btn') {
            btn.push(item.key);
          } else {
            menu.push(item.path!);
            btn.push(item.key);
          }
        });
        // console.log(menu, btn);
        this.setBtnPermission(btn);
        const accessRoutes = filterAsyncRouter(asyncRoutes, menu);
        // console.log(accessRoutes);
        this.setPermitRoues(accessRoutes);
        resolve(accessRoutes);
      });
    },
    addErrorRoute() {
      if (this.hasErrorRoute) return;
      router.addRoute(errRoute);
      this.hasErrorRoute = true;
    },
    clearPermission() {
      this.btnPermissions = [];
      this.permitRoutes = constantRoutes;
      this.hasErrorRoute = false;
    }
	}
});
