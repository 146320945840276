import { defineStore } from 'pinia';
import { apiUsdkGetRealNameNotice } from '@/api/usdk';

export interface GlobalState {
  isShowNotice: boolean;
}

export const useGlobalStateStore = defineStore('notice-base', {
	state:() => ({
		isShowNotice: false,
	}),
	actions: {
    async getNotice (gid: number){
      const res = await apiUsdkGetRealNameNotice({
        gid
      });
      if (res.code === 0) {
        this.isShowNotice = res.data.idCardTip;
      }
    }
	}
});
