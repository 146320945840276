import req from '@/utils/request';
import { JSON_GET, JSON_POST } from './axiosConfig';
// 登录
export const apiLogin = (params: any) => {
  return req.request({
		url: '/ultra/login',
		method: 'post',
		data: params,
		errNotice: true,
		successNotice: true
	});
};

// 登出
export const apiLogout = (params?: any) => {
	return req.post('/ultra/auth/logout', params, true, true);
};

/**
 * 获取短信验证码
 * @param params Phone(手机号)  Type: 1 注册；2 找回密码；3 登录；4 修改密码； 5 邮箱账号激活； 6 修改手机号；  7 修改邮箱
 * @returns 
 */
export const apiGetSmsCode = (params: any) => {
	return req.request({
		url: '/ultra/sms/send',
		method: 'post',
		data: params,
		errNotice: true,
		successNotice: true
	});
};

// 获取用户信息
export const apiGetUserInfo = (params?: any) => {
	return req.get('/ultra/user/info', params);
};

// 设置用户昵称
export const apiUserSetNickname = (params?: any) => {
  return req.request({
    url: '/ultra/api/user/setUsername',
    method: 'put',
    data: params,
    errNotice: true,
    successNotice: true
  });
};

// 修改邮箱
export function apiSetEmail (params?: any) {
  return req.request({
    url: '/ultra/user/setEmail',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    errNotice: true,
    successNotice: true
  });
}

// 修改手机号
export function apiSetPhone (params?: any) {
  return req.request({
    url: '/ultra/user/setPhone',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    errNotice: true,
    successNotice: true
  });
}

/**
 * 手机发短信
 * @param {*} params Phone(手机号)  Type: 1 注册；2 找回密码；3 登录；4 修改密码； 5 邮箱账号激活； 6 修改手机号；  7 修改邮箱
 * @returns
 */
 export function apiSendSms (params?: any) {
  return req.request({
    url: '/ultra/sms/send',
    method: 'post',
    data: params,
    errNotice: true,
    successNotice: true
  });
}

// 修改密码
export function apiSetPwd (params?: any) {
  return req.request({
    url: '/ultra/user/setPwd',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    errNotice: true,
    successNotice: true
  });
}

// 团队管理列表
export function apiGetTeamManageList (params?: any) {
  return req.request({
    url: '/ultra/admin/list',
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });
}

// 角色列表
export function apiRoleList (params?: any) {
  return req.request({
    url: '/ultra/role/list',
    method: 'get',
    params: params
  });
}

// 上级角色组
export function apiRoleMyRoleList (params?: any) {
  return req.request({
    url: '/ultra/role/myRoleList',
    method: 'get',
    params: params
  });
}

// 角色详情
export function apiRoleDetail (params: any) {
  return req.request({
    url: '/ultra/role/detail',
    method: 'get',
    params: params,
    errNotice: true
  });
}

// 列表角色删除
export function apiRoleDelete (params: any) {
  return req.request({
    url: '/ultra/role/delete',
    method: 'post',
    data: params,
    errNotice: true,
    successNotice: true
  });
}

// 协助者权限
export function apiAdminRoles (params: any) {
  return req.request({
    url: '/ultra/admin/roles',
    method: 'get',
    params: params
  });
}

// 权限游戏列表
export function apiAdminGetGameList (params: any) {
  return req.request({
    url: '/ultra/admin/getGameList',
    method: 'get',
    params: params
  });
}

// 授权
export function apiAdminAuth (params: any) {
  return JSON_POST('/ultra/admin/auth', params, true, true);
}

// 邀用户
export function apiAdminInvite (params: any) {
  return JSON_POST('/ultra/admin/invite', params, true, true);
}

// 子账号启停
export function apiStopOrStartAccount (params: any) {
  return JSON_POST('/ultra/user/able', params);
}

// 查询用户名
export function apigetUserNames (params: any) {
  return JSON_GET('/ultra/user/getUserNames', params);
}
