import "core-js/modules/es.array.push.js";
import { useUserStore } from '@/store/modules/user';
const useLogout = () => {
  const {
    logout
  } = useUserStore();
  const router = useRouter();
  const handleLogout = () => {
    logout().then(res => {
      router.push('/login');
    });
  };
  return handleLogout;
};
export default useLogout;