import { defineStore } from 'pinia';
import { apiGameInfo, apiUserSelectGame } from '@/api/app';

export interface GameInfo {
  Icon: string,
  Id: number,
  Name: string,
  PArea: 1,
  PlayType: number,
  CompanyId: number,
  CreateAt: number,
  PackageSize: number,
  PublishStatus: number,
  ScreenType: number,
  Status: number,
  TimeZone: string,
  UpAt: number,
  UpdatedAt: number
}

export const useAppStore = defineStore('app', {
	state: () => ({
		gameInfo: {} as GameInfo,
		commentKey: '',
		isOpenComment: false,
		gameType: 'app',
	}),
	actions: {
		setGameInfo(gameInfo: any) {
			this.gameInfo = gameInfo;
			sessionStorage.setItem('app', JSON.stringify({ gameInfo }));
      if (gameInfo.Id) {
				apiUserSelectGame({ game_id: gameInfo.Id }).then(res => {
					// 保存用户上次操作的游戏
				});
			}
		},
    async getGameInfo(GameId: number) {
      const res = await apiGameInfo({ GameId });
      return res;
    },
	},
	persist: {
		enabled: true,
		strategies: [
			{
				storage: sessionStorage,
				paths: ['gameInfo']
			}
		]
	}
});
