import req from '@/utils/request';
import { ResponseData } from '@ultra/share/utils/request';
import { globalComAxios, internalUsdkJsonComAxios } from './axiosConfig';

// usdk
export function usdkComAxios (path: string, params: any, errNotice = true, successNotice = false, type='protected'): Promise<ResponseData> {
	return req.request({
		url: `/ultra/${type}/app/send/usdk${path}`,
		method: 'post',
		appid: 1,
		data: params,
		path,
		errNotice: errNotice,
		successNotice: successNotice
	});
}

export function comAxios (path:string, params: any, errNotice = true, successNotice = false) {
	return req.request({
    url: '/ultra/app/send',
		method: 'post',
		appid: 1,
		path,
		data: params,
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// sdkjson
export function usdkJsonComAxios (path: string, params: any, errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/protected/app/send/usdk' + path,
		method: 'post',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		},
		appid: 1,
		data: params,
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// 国内
export function internalComAxios (path: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/app/send',
		method: 'post',
		appid: 1,
		path: '/msdk/send',
		data: Object.assign(params || {}, { path }),
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// 代金券
export function pdComAxios (path: string, params = {}, errNotice = true, successNotice = false) {
	return req.request({
		url: '/ultra/app/send',
		method: 'post',
		appid: 1,
		path: '/pb/send',
		data: Object.assign(params, { path }),
		errNotice: errNotice,
		successNotice: successNotice
	});
}

// 批量获取游戏信息
export function apiUsdkBatchGetGameInfo (params?: any) {
	return usdkComAxios('/sdk/u_gameInfoList', params);
}

// 获取渠道配置列表
export function apiUsdkGetChannelConfigList (params?: any) {
	// return usdkComAxios('/sdk/u_cfgList', params);
	return usdkComAxios('/v2/channel/simpleList', params);
}

export function apiUsdkGetGameChannel (params: any) {
	return usdkComAxios('/sdk/getGameChannelList', params);
}

// 禁用/启用渠道
export function apiUsdkCfgChangeChannelStatus (params: any) {
	return usdkComAxios('/sdk/u_cfgChangeChannelStatus', params, true, true);
}

// 道具列表
export function apiUsdkGoodsList (params?: any) {
	return usdkComAxios('/sdk/u_goodsList', params, true, false);
}

// 删除道具
export function apiUsdkDelGoods (params?: any) {
	return usdkComAxios('/sdk/u_delGoods', params, true, true);
}

// 更新道具
export function apiUsdkUpdateGoods (params?: any) {
	return usdkComAxios('/sdk/u_updateGoods', params, true, true);
}

// 获取货币类型
export function apiUsdkGetCurrencyList (params?: any) {
	return usdkComAxios('/v32/global/hg_currencyList', params);
}

// 批量添加道具
export function apiUsdkbatchAddGoods (params?: any) {
	return usdkComAxios('/sdk/u_batchAddGoods', params, true, true);
}

// 保存游戏回调地址
export function apiUsdkSaveCallbackUrl (params?: any) {
	return usdkComAxios('/sdk/u_saveCallbackUrl', params, true, true);
}

// 获取渠道插件参数详情
export function apiUsdkGetChannelPluginsNew (params?: any) {
	return usdkComAxios('/sdk/getChannelPlugConfDetailNew', params);
}

// 获取渠道签名
export function apiUsdkGetChannelSign (params?: any) {
	return usdkComAxios('/sdk/u_getChannelPackageSignNew', params);
}

// 获取公共签名
export function apiUsdkGetPublicSign (params?: any) {
	return usdkComAxios('/sdk/u_getChannelCommonSign', params);
}

// 保存渠道签名
export function apiUsdkSaveChannelSign (params?: any) {
	return usdkComAxios('/sdk/u_setChannelPackageSignNew', params, true, true);
}
// 获取渠道配置的插件
export function apiUsdkGetChannelPlugins (params?: any) {
	return usdkComAxios('/sdk/getPlugConf', params);
}
// 获取cps插件参数
export function apiUsdkGetCpsPlugins (params?:any) {
	return usdkComAxios('/cps/package/replugin', params);
}
// 保存渠道插件配置
export function apiUsdkSaveChannelPluginsNew (params: any) {
	return usdkComAxios('/sdk/setChannelPlugConfDetailNew', params, true, true);
}

// 新增测试账号
export function apiUsdkCreateTestAccount (params: any) {
	return usdkJsonComAxios('/sample/sandbox/batchAdd', params, true, true);
}

// 获取测试账号列表
export function apiUsdkGetTestAccountList (params: any) {
	return usdkComAxios('/sample/sandbox/list', params);
}

// 删除测试账号
export function apiUsdkDelTestAccount (params: any) {
	return usdkComAxios('/sample/sandbox/delete', params);
}

// 获取国内和全球的订单
export function apiUsdkQueryOrders (params: any) {
	return usdkComAxios('/v2/query/order', params);
}

// 查询国内ios订单
export function apiUsdkMidstQuery (params?: any) {
	return internalComAxios('/orderlist/appstore/queryTransactionInfo', params);
}
// 查询用户ios订单
export function apiUsdkMidstOrderList (params?: any) {
	return internalComAxios('/orderlist/appstore/getCompensateOrderList', params);
}


// 获取渠道icon
export function apiUsdkGetChannelIcon (params: any) {
	return usdkComAxios('/sdk/u_getIconConf', params);
}

// 获取渠道角标
export function apiUsdkGetChannelCorner (params: any) {
	return usdkComAxios('/sdk/u_getIconCorner', params);
}

// 保存渠道icon
export function apiUsdkSaveChannelIcon (params: any) {
	return usdkComAxios('/sdk/u_setIconConfNew', params, true, true);
}

// 单个获取闪屏
export function apiUsdkSingleGetSplash (params: any) {
	return usdkComAxios('/sdk/u_getSplashConf', params);
}

// 获取通用闪屏
export function apiUsdkGetCommonSplash (params: any) {
	return usdkComAxios('/sdk/u_getChannelCommonSplashNew', params);
}

// 保存闪屏
export function apiUsdkSaveSplashNew (params: any) {
	return usdkComAxios('/sdk/u_setSplashConfNew', params, true, true);
}

// 获取渠道公共配置
export function apiUsdkGetChannelCommonConfig (params: any) {
	return usdkComAxios('/v31/package/getPkgCommonInfo', params);
}

// 获取渠道参数配置v2
export function apiUsdkGetParamConfV2 (params: any) {
	return usdkComAxios('/v2/channel/param/conf', params, true, false);
}

// 提交渠道参数配置
export function apiUsdkSetParamConf (params: any) {
	return usdkComAxios('/sdk/u_setParamConf', params, true, true);
}

// 获取官方渠道名称
export function apiUsdkGetOfficialInit (params: any) {
	return usdkComAxios('/init/official', params, true, false, 'public');
}

// 获取游戏主题配置
export function apiUsdkSwitchConfGameTheme (params: any) {
	return usdkComAxios('/v2/channel/skin/conf', params, false);
	// return usdkComAxios('/switchConf/m_assetList', params, true, false);
}

// 修改游戏全球主题配置
export function apiUsdkSwitchConfSaveGlobalTheme (params: any) {
	return usdkComAxios('/v2/channel/gl/conf/edit', params, true, true);
}

// 修改游戏国内主题配置
export function apiUsdkSwitchConfSaveInternalTheme (params: any) {
	return usdkComAxios('/v2/channel/cl/conf/edit', params,  true, true);
}

// 获得自定义配置列表
export function apiUsdkGetCustomParamEdit (params: any) {
	return usdkComAxios('/sdk/u_getCustomParamEdit', params, true, false);
}

// 删除参数
export function apiUsdkDelCustomParamEdit (params: any) {
	return usdkComAxios('/sdk/u_delCustomParamEdit', params, true, true);
}
// 提交自定义配置列表
export function apiUsdkSetCustomParamEdit (params: any) {
	// return usdkComAxios('/sdk/u_setCustomParamEdit', params, true, true);
	return usdkComAxios('/sdk/u_setCustomParamEditNew', params, true, true);
}

// 获取游戏渠道配置
export function apiUsdkGetConfigDetail (params: any) {
	return usdkComAxios('/admin/config/detail', params);
}
// 获取渠道客户端参数
export function apiUsdkGetChannelClientParams (params: any) {
	return usdkComAxios('/sdk/tool/channelParam', params);
}

// 获取渠道的icon 角标信息
export function apiUsdkGetChannelIconCornerNew (params: any) {
	return usdkComAxios('/sdk/u_getPkgInfo', params);
}

export function apiUsdkSetPackage (params: any) {
	return comAxios('/sdk/u_setPackage', params, true, false);
}

// 获取开关列表（登录管理，禁用管理，实名管理）
export function apiUsdkSwitchConfGetSwitchList (params: any) {
	return usdkComAxios('/switchConf/getSwitchList', params, true, false);
}
// 保存管理开关
export function apiUsdkSwitchConfSaveSwitch (params: any) {
	return usdkComAxios('/switchConf/saveSwitch', params, true, true);
}

// 批量保存协议
export function apiUsdkBatchAgreement (params: any) {
	return usdkComAxios('/sdk/u_setAgreementsBatchV2_batch', params, true, true);
}

// 第三方sdk权限列表
export function apiUsdkThirdSdkPermissionList (params: any) {
	return usdkComAxios('/childrenPri/getGamePermissionList', params);
}

// 批量保存第三方sdk权限
export function apiUsdkSaveThirdSdkPermission (params: any) {
	return usdkComAxios('/childrenPri/batchUpdatePermission', params, true, true);
}

// 获取配置好的权限列表
export function apiUsdkBatchGetAgreementsV2 (params: any) {
	return usdkComAxios('/sdk/u_getAgreementsBatchV2', params);
}

// 批量提升版本号
export function apiUsdkBatchUpdateAgreementsVersion (params: any) {
	return usdkComAxios('/sdk/u_upAgreementsVersion', params, true, true);
}

// 批量设置协议开关
export function apiUsdkBatchSetAgreementSwitch (params: any) {
	return usdkComAxios('/sdk/u_setAgreementsSwitchBatch', params, true, true);
}

// 批量设置协议内容
export function apiUsdkBatchSetAgreement (params: any) {
	return usdkComAxios('/sdk/u_setAgreementsBatchV2', params, true, true);
}

// 游戏能添加的渠道
export function apiUsdkCfgGetAddList (params: any) {
	// return usdkComAxios('/sdk/u_cfgGetAddList', params, true, false);
	return usdkComAxios('/v31/game/channelconf/u_cfgAddList', params, true, false);
}

// 为游戏添加渠道
export function apiUsdkCfgAddChannel (params: any) {
	return usdkComAxios('/sdk/u_cfgAddChannel', params, true, true);
}
	// 中信部实名参数
export function apiUsdkRealNameConfigList (params = {}) {
	return internalComAxios('/idcard/list', params);
}

// 新增实名参数
export function apiUsdkSaveRealNameConfig (params = {}) {
	return internalComAxios('/idcard/save', params, true, true);
}

// 修改实名参数
export function apiUsdkUpdateRealNameConfig (params = {}) {
	return internalComAxios('/idcard/update', params, true, true);
}

// 删除实名参数
export function apiUsdkDelRealNameConfig (params = {}) {
	return internalComAxios('/idcard/del', params, true, true);
}

// 获取实名认证开关 - 按项目
export function apiUsdkGetRealNameProductList (params: any) {
	return internalComAxios('/switch/realname/product/list', params);
}

// 修改实名认证开关 - 按项目
export function apiUsdkUpdateRealNameProduct (params: any) {
	return internalComAxios('/switch/realname/product/saveConf', params, true, true);
}

// 获取国内短信列表
export function apiUsdkGetSmsList (params: any) {
	return usdkComAxios('/v32/sdk/prop/m_smsList', params);
}

// 保存国内短信列表
export function apiUsdkSaveSmsList (params: any) {
	return usdkComAxios('/v32/sdk/prop/m_smsSaveConf', params, true, true);
}

// 获取线上渠道配置
export function apuUsdkGetChannelOnlineConfig (params: any) {
	return usdkComAxios('/v2/channel/list', params);
}
// 获取代金券列表
export function apiUsdkGetVoucherList (params: any) {
	return pdComAxios('/admin/actList.do', params);
}

// 删除代金券
export function apiUsdkUpdateVoucher (params: any) {
	return pdComAxios('/admin/statusActivity.do', params, true, true);
}

// 新增代金券
export function apiUsdkAddVoucher (params: any) {
	return pdComAxios('/admin/addActivity.do', params, true, true);
}

// 更新代金券
export function apiUsdkEditVoucher (params: any) {
	return pdComAxios('/admin/editActivity.do', params, true, true);
}

// 获取公告列表
export function apiUsdkGetAnnounceProductList (params: any) {
	return usdkComAxios('/switchConf2/m_sdkProductList', params);
}

// 获取注销与联系方式(国内)
export function apiUsdkGetQuestionConf (params: any) {
	return usdkComAxios('/v2/channel/cl/conf', params);
}

// 获取联系客服地址
// export function apiUsdkSwitchConfBouy (params: any) {
// 	return usdkComAxios('/switchConf/buoy', params);
// }

// 修改浮标配置
// export function apiUsdkSwitchConfUpdateBouy (params: any) {
// 	return usdkComAxios('/switchConf/m_buoyUpdateConf', params);
// }

// 修改公告信息
export function apiUsdkUpdateAnnounceProduct (params: any) {
	return usdkComAxios('/switchConf2/m_sdkProductUpdate', params, true, true);
}

// 注销与联系方式编辑(国内)
export function apiUsdkGetQuestionConfEdit (params: any) {
	return usdkComAxios('/v2/channel/cl/conf/edit', params);
}

// 页面配置列表
export function apiUsdkPageConf (params: any) {
	return usdkComAxios('/v2/channel/page/conf', params, true);
}

//  全球页面配置修改
export function apiUsdkPageConfEdit (params: any) {
	return usdkComAxios('/v2/channel/gl/conf/edit', params, true, true);
}

// 获取用户协议列表
export function apiUsdkGetAgreementList (params: any) {
	return usdkComAxios('/v32/global/hg_getAllAgreementList', params);
}

// 保存用户协议
export function apiUsdkSaveAgreement (params: any) {
	return usdkComAxios('/v32/global/hg_saveAgreement', params, true, true);
}

// 保存全球配置
export function apiUsdkSaveGlobalSdk (params: any) {
	return usdkComAxios('/v32/global/hg_saveSdkConfig', params, true, true);
}

// 获取地区列表
export function apiUsdkGetRegionList (params: any) {
	return globalComAxios('/api/ext/conf/region/list', params);
}
// 获取ip详情
export function apiUsdkIpLimitDetail (params:any) {
	return globalComAxios('/api/ext/conf/iplimit/detail', params);
}

// 修改ip
export function apiUsdkUpdateIpLimit (params: any) {
	return globalComAxios('/api/ext/conf/iplimit/update', params, true, true);
}

// 获取签名校验开关
export function apiUsdkSwitchConfGetCheckSign (params: any) {
	return usdkComAxios('/switchConf/getCheckSign', params, true, false);
}

// 批量保存禁用开关
export function apiUsdkSwitchConfBatchFobiddenSwitch (params: any) {
	return usdkComAxios('/switchConf/saveSwitchBatch2', params, true, true);
}
// 保存签名校验
export function apiUsdkSwitchConfSaveCheckSign (params: any) {
	return usdkComAxios('/switchConf/saveCheckSign', params, true, false);
}

// 获取国内或者全球产品id
export function apiUsdkGetProductId (params: any) {
	return usdkComAxios('/sdk/u_heroParam', params);
}

// 获取相同的渠道
export function apiUsdkCheckParams (params: any) {
	return usdkComAxios('/sdk/u_checkParam', params);
}


// 保存国内登录开关和参数
export function apiUsdkSaveInternalLoginSwitchConf (params: any) {
	return usdkJsonComAxios('/switchConf/m_setSwitchProductLoginListWithConf', params, true, true);
}
// 获取强更版本
export function apiUsdkSwitchConfGetVersion (params: any) {
	return usdkComAxios('/switchConf/getVersion', params, true, false);
}

// 删除强更版本
export function apiUsdkSwitchConfDelVersion (params: any) {
	return usdkComAxios('/switchConf/delVersion', params, true, true);
}

// 保存强更版本
export function apiUsdkSwitchConfSaveVersion (params: any) {
	return usdkComAxios('/switchConf/saveVersion', params, true, true);
}

// 全球SDK 登录配置
export function apiUsdkGlobalLoginConf (params: any) {
	return usdkComAxios('/v2/channel/login/conf', params);
}

// 全球SDK 登录配置编辑
export function apiUsdkGlobalLoginConfEdit (params: any) {
	return usdkComAxios('/v2/channel/gl/conf/edit', params, true, true);
}

// 保存全球登录开关和参数
export function apiUsdkSaveGlobalLoginSwitchConf (params: any) {
	return usdkJsonComAxios('/v32/global/hg_saveSdkConfigAndConfBatch', params, true, true);
}
// 获取邮箱登录配置状态
export function apiUsdkGetEmailConfigStatus (params: any) {
	return usdkJsonComAxios('/v32/global/hg_isBindMail', params);
}
// 获取浮标列表
export function apiUsdkGetBuoyList (params: any) {
	return usdkComAxios('/buoy/buoyItemList', params);
}

// 设置浮标公共开关
export function apiUsdkSetBuoyCommonStatus (params: any) {
	return usdkComAxios('/buoy/setBuoyStatus', params, true, true);
}

// 批量保存浮标配置
export function apiUsdkBatchSetBuoy (params: any) {
	return usdkComAxios('/buoy/batchUpdateBuoyItems', params, true, false);
}

// 批量保存通用/多个渠道浮标配置
export function apiUsdkBatchCommonSetBuoy (params: any) {
	return usdkComAxios('/buoy/batchUpdateBuoyGames', params, true, false);
}

// 礼包列表
export function apiUsdkSwitchConfGiftList (params: any) {
	return usdkComAxios('/switchConf2/m_getGiftpkgList', params, true, false);
}

// 礼包上架
export function apiUsdkSwitchConfGiftShelve (params: any) {
	return usdkComAxios('/switchConf2/m_getGiftpkgShelve', params, true, true);
}

// 礼包下架
export function apiUsdkSwitchConfGiftOffShelve (params: any) {
	return usdkComAxios('/switchConf2/m_getGiftpkgOffshelve', params, true, true);
}

// 新增修改礼包
export function apiUsdkSwitchConfGiftSave (params: any) {
	return usdkComAxios('/switchConf2/m_getGiftpkgSave', params, true, true);
}

// 礼包码文件列表
export function apiUsdkSwitchConfGiftCodeList (params: any) {
	return usdkComAxios('/switchConf2/m_codeFileList', params, true, false);
}

// 礼包码领取明细
export function apiUsdkSwitchConfGiftReceiveList (params: any) {
	return usdkComAxios('/switchConf2/m_receiveList', params, true, false);
}

// 追加礼包码
export function apiUsdkSwitchConfGiftUploadCode (params: any) {
	return usdkComAxios('/switchConf2/m_getGiftpkgUploadCode', params, true, true);
}

// 查询账户
export function apiUsdkQueryAccount (params: any) {
	return usdkComAxios('/v2/query/account', params);
}

// 获取项目列表
export function apiUsdkSwitchConfOfficialProjectList (params: any) {
	return usdkComAxios('/switchConf/m_projectList', params, true, false);
}

// cps分包列表
export function apiUsdkCpsPackagesList (params: any) {
	return usdkComAxios('/cps/package/list', params);
}

// 获取全球退款回调地址
export function apiUsdkGetGlobalCallback (params: any) {
	return globalComAxios('/api/ext/data/getRefusedList', params);
}

// 保存游戏退款回调地址
export function apiUsdkSaveRefundCallbackUrl (params: any) {
	return usdkComAxios('/sdk/u_saveRefundCallbackUrl', params, true, true);
}

// 保存全球退款回调地址
export function apiUsdkSaveGlobalCallback (params: any) {
	return globalComAxios('/api/ext/data/setRefusedList', params, true, true);
}

// 国内 支付描述
export function apiUsdkPayDesc (params:any) {
	return usdkComAxios('/v2/channel/payDesc/conf', params);
}

// 国内 支付描述编辑
export function apiUsdkPayDescEdit (params:any) {
	return usdkComAxios('/v2/channel/payDesc/conf/edit', params, true, true);
}

// 保存国内支付开关和参数
export function apiUsdkSaveInternalPaySwitchConf (params: any) {
	return internalUsdkJsonComAxios('/switch/pay/saveSwitchWithConf', params, true, true);
}
	// cps 发布cdn
export function apiUsdkCpsPublishCdn (params: any) {
	return usdkComAxios('/cps/package/cdn_publish', params, true, true);
}

// 取消cdn
export function apiUsdkCpsCancelCdn (params: any) {
	return usdkComAxios('/cps/package/cdn_cancel', params, true, true);
}

// 打包实时数据
export function apiProjectGetStaticData (params: any) {
	return usdkComAxios('/cps/package/statistic', params, true, false);
}

// cps 禁用
export function apiUsdkCpsForbid (params: any) {
	return usdkComAxios('/cps/package/forbid', params, true, true);
}

// 获取定时任务列表
export function apiUsdkGetTaskList (params: any) {
  return usdkComAxios('/cps/package/schedule', params);
}

// cps 分发渠道选项
export function apiUsdkCpsDistributeChannel (params: any) {
	return usdkComAxios('/cps/distribute/channel', params);
}

// 渠道包选项
export function apiUsdkCpsChannelPackage (params: any) {
	return usdkComAxios('/cps/package/channel', params);
}

// 渠道包选项
export function apiUsdkCpsRepack (params: any) {
	return usdkJsonComAxios('/cps/package/restart', params, true, true);
}
// 获取登录状态列表
export function apiUsdkSwitchLoginList (params:any) {
	return usdkComAxios('/switchConf/m_switchLoginList', params, true, false);
}
// 修改登录状态列表
export function apiUsdkSwitchLoginUpdate (params:any) {
	return usdkComAxios('/switchConf/m_switchLoginUpdate', params, true, true);
}

// 删除登录开关
export function apiUsdkSwitchLoginDelete (params:any) {
	return usdkComAxios('/switchConf/m_switchLoginDelete', params, true, true);
}

// 创建CPS项目 (官方分包)
export function apiProjectCpsAdd (params: any) {
	return usdkComAxios('/cps/package/create', params, true, true);
	// return sampleAxios('/api/game/agent/channel/package', params, true, true);
}

// cps 分发渠道列表
export function apiUsdkCpsDistributeChannelList (params: any) {
	return usdkComAxios('/cps/distribute/list', params);
}

// cps 分发渠道禁用
export function apiUsdkCpsDistributeForbid (params: any) {
	return usdkComAxios('/cps/distribute/forbid', params, true, true);
}

// cps 分发渠道更新
export function apiUsdkCpsUpdateDistributeChannel (params: any) {
	return usdkComAxios('/cps/distribute/edit', params, true, true);
}

// cps 创建分发渠道
export function apiUsdkCpsCreateChannel (params: any) {
	return usdkComAxios('/cps/distribute/create', params, true, true);
}

// 获取官方安卓及其镜像渠道
export function apiUsdkGetAndChannelList (params: any) {
	return usdkComAxios('/channelImg/getAndChannelList', params);
}

// 获取渠道包列表
export function apiUsdkGetOfficialList (params: any) {
	return usdkComAxios('/v2/channel/package/page', params);
}

// 删除渠道包
export function apiUsdkDelOfficialPackage (params: any) {
	return usdkComAxios('/v2/channel/package/del', params);
}

// 设置备注
export function apiUsdkPackageSetRemark (params: any) {
	return usdkComAxios('/sdk/package/setRemark', params, true, true);
}

// 获取最新渠道包
export function apiUsdkGetMaxVersionOfficialPackage (params: any) {
	return usdkComAxios('/v2/channel/package/recent', params);
}
// 解析渠道包
export function apiUsdkParseOfficialPackage (params: any) {
	return usdkComAxios('/v2/channel/package/parse', params);
}

// 添加渠道包
export function apiUsdkAddOfficialPackage (params: any) {
	return usdkComAxios('/v2/channel/package/add', params);
}

// 保存全球支付开关和参数
export function apiUsdkSaveGlobalPaySwitchConf (params: any) {
	return usdkJsonComAxios('/v32/global/hg_savePaySwitchAndConf', params, true, true);
}

// 获取档位配置
export function apiUsdkGetGoodsConfEdit (params: any) {
	// return usdkComAxios('/sdk/u_getGoodsConfEdit', params, true, false);
	return usdkComAxios('/v2/channel/goods/list', params, true, false);
}

// 修改渠道商品配置
export function apiUsdkChannelGoodsUpdate (params: any) {
	return usdkComAxios('/v2/channel/goods/edit', params, true, true);
}

// 获取支付类型
export function apiUsdkGetPayType (params?: any) {
	return usdkComAxios('/v32/global/hg_payType', params);
}

//获取登录列表
export function apiUsdkGetLoginList (params?: any) {
	return usdkComAxios('/valid/login', params);
}

// 获取订单列表
export function apiUsdkGetPayList (params?: any) {
	return usdkComAxios('/valid/pay', params);
}

// 其他渠道迁移callback回调地址
export function apiUsdkOtherChannelCallback (params: any) {
	return usdkComAxios('/sdk/u_setOtherCallbackUrl', params, true, true);
}
// 获取浮标列表
export function apiUsdkBuoyConfList (params: any) {
	return usdkComAxios('/switchConf/buoyList', params, true, false);
}
// 修改浮标配置
export function apiUsdkBuoyConfUpdate (params: any) {
	return usdkComAxios('/switchConf/m_buoyUpdateConf', params,true,true);
}

interface GetH5ChannelList {
	gid: number;
}
// 获取H5渠道配置列表
export function apiUsdkGetH5ChannelList (params: GetH5ChannelList) {
	return usdkComAxios('/v2/h5conf/channelList', params);
}

export interface GetChannelH5List {
	gid: number;
	cid: number;
}
// 获取渠道H5列表
export function apiUsdkGetChannelH5List (params: GetChannelH5List) {
	return usdkComAxios('/v2/h5conf/channelH5List', params);
}

// 更新渠道的h5
export function apiUsdkUpdateH5Channel (params: any) {
	return usdkComAxios('/v2/h5conf/updatePid', params);
}

export interface AddEditH5 {
	gid?: number;
	id?: number;
	webId: string;
	webUrl: string;
	name: string;
	orientation: number;
	fn:btnOptionItemType[];
}
export interface btnOptionItemType {
	fnType: number;
	icon: any;
	id?: number;
	position: number;
	status: number;
}
// 新增或修改H5
export function apiUsdkAddEditH5 (params: AddEditH5) {
	return usdkJsonComAxios('/v2/h5conf/updateH5', params, true, true);
}

// h5页面管理列表
export function apiUsdkH5ConfigList (params: any) {
	return usdkComAxios('/v2/h5conf/list', params);
}

// 获取实名认证提示状态
export function apiUsdkGetRealNameNotice (params: any) {
	return usdkComAxios('/init/notice', params);
}
