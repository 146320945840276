import { defineStore } from 'pinia';

export interface Task {
  id: number;
  api: (params?: any) => Promise<any>;
  page: string;
  customPageSize?: number;
  pageSize: string;
  params: any;
  data: string;
  total: string;
  excel: {
    title: string,
    header: string[]
    field: string[]
  }
}

let id = 1;
export const useTaskStore = defineStore('task', {
	state: () => ({
		showDrawer: false,
    tasksList: <Task[]>[]
	}),
	actions: {
    setShowTask (show: boolean) {
      console.log('setShowTask=======================>', show);
      this.showDrawer = show;
    },
    setTask (task: Omit<Task, 'id'>) {
      return new Promise((resolve, reject) => {
        this.tasksList.push({ id: id++, ...task });
        this.setShowTask(true);
        resolve(task);
      });
    },
    delTask (id: number) {
      return new Promise((resolve, reject) => {
        for (let index = 0; index < this.tasksList.length; index++) {
          const task = this.tasksList[index];
          if (task.id === id) {
            this.tasksList.splice(index, 1);
            return;
          }
        }
        resolve(id);
      });
    },
    clearTasks () {
      return new Promise((resolve, reject) => {
        this.tasksList = [];
        resolve(true);
      });
    }
	}
});
