import { createApp } from 'vue';
import App from './app.vue';
import router from './router';
import microApp from '@micro-zoe/micro-app';
import 'element-plus/dist/index.css';
import '@ultra/share/style/index.less';
import '@/assets/style/cascader.less';
import { setupStore } from '@/store';
import { formatDate } from '@ultra/share/utils/filter';
import UCard from '@ultra/share/components/UCard/index.vue';
import UCardPane from '@ultra/share/components/UCard/cardPane.vue';
import UQuestion from '@ultra/share/components/UQuestion/index.vue';
import UUpload from '@ultra/share/components/UUpload/index.vue';
import { apiUsdkGetOfficialInit } from '@/api/usdk';
import drag from '@ultra/share/utils/drag';
import hasPermission from '@ultra/share/utils/permission';
import { usePermissionStore } from '@/store/modules/permission';
import { datafluxRum } from '@cloudcare/browser-rum';

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $filters: {
      formatDate: typeof formatDate
    },
    $channel: typeof OFFICIALCHANNEL
  }
}


const OFFICIALCHANNEL = {
  OFFICIAL: '',
  OFFICIAL_CHANNEL: '',
  OFFICIAL_IOS_CHANNEL: '',
  GLOBAL_OFFICIAL_CHANNEL: '',
  GLOBAL_OFFICIAL_IOS_CHANNEL: '',
  OFFICIAL_CHANNEL_ID: '',
  OFFICIAL_IOS_CHANNEL_ID: '',
  GLOBAL_OFFICIAL_CHANNEL_ID: '',
  GLOBAL_OFFICIAL_IOS_CHANNEL_ID: '',
  INTERNAL: [] as string[],
  GLOBAL: [] as string[]
};

const dataCenterUrl = process.env.VUE_APP_DATACENTER_URL + 'micro/data-center';
const popularizeUrl = process.env.VUE_APP_POPULARIZE_URL + 'micro/popularize';
const dataMonitorUrl = process.env.VUE_APP_DATAMONITOR_URL + 'micro/data-monitor';

microApp.start({
  preFetchApps: [
    { name: '@ultra/data-center', url: dataCenterUrl },
    { name: '@ultra/popularize', url: popularizeUrl },
    { name: '@ultra/data-monitor', url: dataMonitorUrl },
  ],
});
const app = createApp(App);
setupStore(app);

app.config.globalProperties.$filters = {
  formatDate: (date: number, fmt?: string) => {
    return formatDate(date, fmt);
  },
};

app.component('UCard', UCard);
app.component('UCardPane', UCardPane);
app.component('UQuestion', UQuestion);
app.component('UUpload', UUpload);

app.directive('drag', drag);

hasPermission(app, usePermissionStore);

const initOfficial = () => {
  apiUsdkGetOfficialInit({}).then((res: any) => {
    if (res.code === 0) {
      OFFICIALCHANNEL.OFFICIAL_CHANNEL = res.data.ma.channel;
      OFFICIALCHANNEL.OFFICIAL_CHANNEL_ID = res.data.ma.id;
      OFFICIALCHANNEL.OFFICIAL = res.data.ma.channel;
      OFFICIALCHANNEL.OFFICIAL_IOS_CHANNEL = res.data.mi.channel;
      OFFICIALCHANNEL.OFFICIAL_IOS_CHANNEL_ID = res.data.mi.id;
      OFFICIALCHANNEL.GLOBAL_OFFICIAL_CHANNEL = res.data.ga.channel;
      OFFICIALCHANNEL.GLOBAL_OFFICIAL_CHANNEL_ID = res.data.ga.id;
      OFFICIALCHANNEL.GLOBAL_OFFICIAL_IOS_CHANNEL = res.data.gi.channel;
      OFFICIALCHANNEL.GLOBAL_OFFICIAL_IOS_CHANNEL_ID = res.data.gi.id;
      OFFICIALCHANNEL.INTERNAL = [res.data.ma.channel, res.data.mi.channel];
      OFFICIALCHANNEL.GLOBAL = [res.data.ga.channel, res.data.gi.channel];
    }
    app.config.globalProperties.$channel = OFFICIALCHANNEL;
  });
};

initOfficial();

// 观测云监控
datafluxRum.init({
  applicationId: 'ultra_admin',
  datakitOrigin: 'https://datakitfront.yingxiong.com/', // 协议（包括：//），域名（或IP地址）[和端口号]
  env: process.env.VUE_APP_MODE || process.env.NODE_ENV,
  version: '1.0.0',
  trackInteractions: true,
  traceType: 'ddtrace', // 非必填，默认为ddtrace，目前支持 ddtrace、zipkin、skywalking_v3、jaeger、zipkin_single_header、w3c_traceparent 6种类型
  // allowedTracingOrigins: ['https://api.example.com', 'https://.*.my-api-domain.com/'],  // 非必填，允许注入trace采集器所需header头部的所有请求列表。可以是请求的origin，也可以是是正则
});

app.use(router).mount('#app');
