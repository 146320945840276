import Request from '@ultra/share/utils/request';
import { ElMessage } from 'element-plus';
import router from '@/router';
import { useUserStore } from '@/store/modules/user';

let timer: ReturnType<typeof setTimeout>;
const request = new Request({
  baseURL: '',
  timeout: 10000,
  successCallback: (data) => {
    ElMessage.success(data.msg);
    // console.log('success', data);
  },
  errorCallback: (data) => {
    console.log('error', data);
    if (data.code === 14) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        ElMessage.closeAll();
        ElMessage.error(data.msg || '接口错误');
      }, 500);
    } else {
      ElMessage.closeAll();
      ElMessage.error(data.msg || '接口错误');
    }
  },
  loginExpired: () => {
    // console.log('loginExpired');
    const { clearCache } = useUserStore();
    clearCache();
    router.push('/login');
  },
  wrongCallback: (msg: string) => {
    ElMessage.closeAll();
    ElMessage.error(msg);
  }
});

export default {
  get: request.get,
  post: request.post,
  put: request.put,
  delete: request.delete,
  request: request.request
};
