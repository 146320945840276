import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/layouts/defaultLayout.vue';
import BlankLayout from '@/layouts/blankLayout.vue';
import { useUserStore } from '@/store/modules/user';
import { usePermissionStore } from '@/store/modules/permission';
import { store } from '@/store';
import { useAppStore } from '@/store/modules/app';
import { apiGameInfo } from '@/api/app';
export const asyncRoutes = [{
  path: '/pack',
  name: 'Pack',
  component: BlankLayout,
  redirect: '/pack/sdk',
  children: [{
    path: '/pack/sdk',
    name: 'SDK',
    component: () => import('@/views/pack/sdk/index.vue'),
    meta: {
      title: 'SDK接入'
    }
  }, {
    path: '/pack/mini-sdk',
    name: 'MiniSdk',
    component: () => import('@/views/pack/miniConf/index.vue'),
    meta: {
      title: '小游戏SDK'
    }
  }, {
    path: '/pack/game-conf',
    name: 'GameConf',
    component: () => import('@/views/pack/gameConf/index.vue'),
    meta: {
      title: '游戏配置'
    }
  }, {
    path: '/pack/test-validity',
    name: 'TestValidity',
    component: () => import('@/views/pack/testValidity/index.vue'),
    meta: {
      title: '测试验证'
    }
  }, {
    path: '/pack/channel',
    name: 'Channel',
    component: () => import('@/views/pack/channel/index.vue'),
    meta: {
      title: '渠道配置'
    }
  }, {
    path: '/pack/packTool',
    name: 'PackTool',
    component: () => import('@/views/pack/packTool/index.vue'),
    meta: {
      title: '打包'
    }
  }]
}, {
  path: '/operate',
  name: 'Operate',
  component: () => import('@/views/operate/index.vue'),
  children: [{
    path: '/operate/login-manage',
    name: 'loginManage',
    component: () => import('@/views/operate/base/loginManage/index.vue'),
    meta: {
      title: '登录管理'
    }
  }, {
    path: '/operate/pay-manage',
    name: 'payManage',
    component: () => import('@/views/operate/base/payManage/index.vue'),
    meta: {
      title: '支付和商品管理'
    }
  }]
}, {
  // 👇 非严格匹配，/popularize/* 都指向 Popularize 页面
  path: '/popularize/:page*',
  name: 'Popularize',
  component: () => import('../views/popularize.vue')
}, {
  // 👇 非严格匹配，/data-center/* 都指向 dataCenter 页面
  path: '/data-center/:page*',
  name: 'DataCenter',
  component: () => import('../views/dataCenter.vue')
}];
export const errRoute = {
  path: '/:catchAll(.*)',
  redirect: '/404'
};
export const constantRoutes = [{
  path: '/',
  component: DefaultLayout,
  redirect: '/home',
  name: 'Entry',
  children: [{
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/index.vue')
  }, {
    path: '/user-center',
    name: 'UserCenter',
    component: () => import('../views/user/userCenter.vue')
  }, {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/setting/index.vue')
  }]
}, {
  // 👇 非严格匹配，/module-app/* 都指向 moduleApp 页面
  path: '/module-app/:page*',
  name: 'moduleApp',
  component: () => import('@/views/moduleApp.vue'),
  meta: {
    title: '内嵌H5管理'
  }
}, {
  // 👇 非严格匹配，/data-monitor/* 都指向 dataMonitor 页面
  path: '/data-monitor/:page*',
  name: 'DataMonitor',
  component: () => import('../views/dataMonitor.vue')
}, {
  path: '/login',
  name: 'Login',
  component: () => import('@/views/user/login.vue')
}, {
  path: '/404',
  name: '404',
  component: () => import('@/views/404.vue')
}];
const router = createRouter({
  history: createWebHistory('/micro'),
  routes: constantRoutes
});
const whiteList = ['Home', 'Login', '404'];
const special = [];
router.beforeEach((to, from, next) => {
  const {
    getUserInfo,
    token,
    logout,
    roles,
    getRole
  } = useUserStore(store);
  // console.log(token);
  const {
    generateRoutes,
    addErrorRoute
  } = usePermissionStore(store);
  const appStore = useAppStore(store);
  // 设置讨论key
  appStore.commentKey = to.name || to.path;
  if (to.meta.title) {
    useTitle(to.meta.title);
  }
  if (token) {
    if (to.path === '/login') {
      next();
    } else {
      getUserInfo().then(info => {
        if (info && !info.Id) {
          logout().then(res => {
            next({
              path: '/login',
              replace: true
            });
          });
        }
        if (roles.length === 0) {
          getRole().then(roles => {
            generateRoutes(roles).then(accessedRouters => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              if (accessedRouters.length) {
                accessedRouters.forEach(_route => {
                  router.addRoute('Entry', _route);
                });
              }
              addErrorRoute();
              const go = () => {
                const redirect = decodeURIComponent(from.query.redirect || to.path);
                if (to.path === redirect) {
                  next({
                    ...to,
                    replace: true
                  });
                } else {
                  // 跳转到目的路由
                  next({
                    path: redirect
                  });
                }
              };
              // 当没有游戏的时候，需要主动调接口，拉取最近操作的游戏
              if (!(appStore.gameInfo && appStore.gameInfo.Id) && info.LastGameId) {
                apiGameInfo({
                  GameId: info.LastGameId
                }).then(res => {
                  if (res.code === 0) {
                    appStore.setGameInfo(res.data);
                  }
                  go();
                });
              } else {
                go();
              }
            });
          }).catch(err => {
            addErrorRoute();
            if (whiteList.includes(String(to.name))) {
              // 在免登录白名单，直接进入
              next();
            } else {
              if (to.path === '/404' || special.includes(String(to.name))) {
                next();
              } else {
                next({
                  path: '/404',
                  replace: true
                });
              }
            }
          });
        } else {
          next();
        }
      });
    }
  } else {
    addErrorRoute();
    if (whiteList.includes(String(to.name))) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    }
  }
});
export default router;