import req from '@/utils/request';
import { JSON_DEL, JSON_GET, JSON_POST, JSON_PUT } from './axiosConfig';
// import { sampleAxios } from './axiosConfig';

// 游戏列表
export function apiAppGameList (params: any) {
	return req.get('/ultra/game/list', params);
}

// 新增游戏
export function apiAppGameAdd (params: any) {
	return req.request({
		url: '/ultra/game/add',
		method: 'post',
		data: params,
		errNotice: true,
		successNotice: true
	});
}

// 更新游戏
export function apiAppGameUpdate (params: any) {
	return req.request({
		url: '/ultra/game/update',
		method: 'post',
		data: params,
		errNotice: true,
		successNotice: true
	});
}

// 游戏详情
export function apiGameInfo (params?: any) {
	return req.get('/ultra/game/info', params);
}

// // 刷新CDN
// export function apiAppRefreshCdn (params: any) {
// 	return sampleAxios('/api/game/refreshCdn', params);
// }

// 用户上次使用的游戏
export function apiUserSelectGame (params: any) {
	return req.request({
		url: '/ultra/user/selectGame',
		method: 'post',
		params,
		errNotice: true
	});
}

// 获取评论列表
export function apiGetCommentList(params: any) {
	return JSON_GET('/ultra/api/v1/comments', params);
}

// 获取评论标签
export function apiGetCommentTags(params: any) {
	return JSON_GET('/ultra/api/v1/comment/tags', params);
}

// 获取评论总数
export function apiGetCommentCount(params: any) {
	return JSON_GET('/ultra/api/v1/comments/count', params);
}

// 修改评论
export function apiUpdateComment(params: any) {
	return JSON_PUT('/ultra/api/v1/comment', params, true, true);
}

// 保存评论
export function apiSaveComment(params: any) {
	return JSON_POST('/ultra/api/v1/comment', params, true, true);
}

// 删除评论
export function apiDeleteComment(params: any) {
	return JSON_DEL('/ultra/api/v1/comment', params, true, true);
}