import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { strToJson } from './index';

interface RequestOptions {
  baseURL: string;
  timeout: number;
  token?: string | (() => string);
  successCallback?: (data: any) => void;
  errorCallback?: (error: any) => void;
  loginExpired?: () => void;
  wrongCallback?: (msg: string) => void;
}
// 接口返回数据接口类型
export interface ResponseData {
  code: number,
  data: any,
  msg: string,
  meta?: any
}

// 扩展请求参数接口
export interface RequestConfig extends AxiosRequestConfig {
  successNotice?: boolean;
  errNotice?: boolean;
  appid?: number;
  path?: string;
}
class Request {
  instance: AxiosInstance;
  $options: RequestOptions;
  public request: (config: RequestConfig) => Promise<ResponseData>;
  constructor (options: RequestOptions) {
    const { baseURL, timeout } = options;
    this.instance = axios.create({
      baseURL,
      timeout,
      withCredentials: true
    });
    this.request = async (config: RequestConfig): Promise<ResponseData> => {
      const { data } = await this.instance(config);
      return data;
    };
    this.$options = options;
    this.init(options);
  }

  init (options: RequestOptions): void {
    // 请求拦截
    this.instance.interceptors.request.use((config: RequestConfig) => {

      const { token, uid } = this.getTokenAndId();
      config.headers!['Access-Token'] = token;
      if (config.method === 'post') {
        if (config.headers && !config.headers['Content-Type']) {
          config.data = qs.stringify(config.data);
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
      }
      if (config.headers) {
        config.headers.appid = config.appid || config.headers.appid || 0;
        config.headers.path = config.path || config.headers.path || '';
        config.headers.uid = uid || '';
      }

      return config;
    },  (error) => {
      return this.err(error);
    });

    // 响应拦截
    this.instance.interceptors.response.use((response: AxiosResponse) => {
      const config: RequestConfig = response.config;
      const code = Number(response.data.code);
      if (response.data) {
        if (code === 0) {
          if (config && config.successNotice) {
            this.$options.successCallback && this.$options.successCallback(response.data);
          }
        } else {
          if (config && config.errNotice) {
            this.$options.errorCallback && this.$options.errorCallback(response.data);
          }
        }
        // 登录过期
        let errCode = [102, 401, 402, 403, 14];
        if (errCode.includes(code)) {
          this.$options.loginExpired && this.$options.loginExpired();
        }

        try {
          const userInfo = JSON.parse(localStorage.getItem('user') || '{}').userInfo;
          const gameInfo = JSON.parse(sessionStorage.getItem('app') || '{}').gameInfo;
          //@ts-ignore
          window.staticFn.setCustomConfig({ gameId: gameInfo.Id });
          //@ts-ignore
          window.staticFn.customEvent('request_record',
              {
                game_id: gameInfo.Id,
                request_path: config.url,
                request_params: config.method === 'get' ? JSON.stringify(strToJson(config.params)) : JSON.stringify(strToJson(config.data)),
                request_method: config.method,
                response_code: response.status,
                response_params: JSON.stringify(response.data),
                gmt_update: new Date().getTime(),
                uid_update: userInfo.Id,
                name_update: userInfo.UserName,
              }
          );
        } catch (e) {
          console.log(e);
        }

        return response || {};
      } else {
        return response;
      }
    }, (error) => {
      return this.err(error);
    });
  }

  getTokenAndId (): { token: string, uid: string } {
    let user:any = localStorage.getItem('user') || '';
    if (user) {
      user = JSON.parse(user) as any;
      return {
        token: user.token,
        uid: user.uid
      };
    }
    return { token: '', uid: '' };
  }

  err (error: any) {
    if (error.message.includes('timeout')) {
      this.$options.wrongCallback && this.$options.wrongCallback(error.message);
    }
    if (error.response) {
      // const data = error.response.data;
      // if (error.response.status === 403) {

      // }
      // if (error.response.status === 401 && !(data.result && data.result.isLogin)) {

      // }
    }
    return { data: { code: 500, msg: error.message || '网络错误' }, status: 500 };
  }

  public get (url: string, params: Record<string, unknown>, errNotice = true, successNotice = false): Promise<ResponseData> {
    return this.request({
      url,
      method: 'get',
      params,
      errNotice,
      successNotice
    });
  }

  public post (url: string, data: Record<string, unknown>, errNotice = true, successNotice = false): Promise<ResponseData> {
    console.log(this);
    return this.request({
      url,
      method: 'post',
      data,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      errNotice,
      successNotice
    });
  }

  public put (url: string, data: Record<string, unknown>, errNotice = true, successNotice = false): Promise<ResponseData> {
    return this.request({
      url,
      method: 'put',
      data,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      errNotice,
      successNotice
    });
  }

  public delete (url: string, data: Record<string, unknown>, errNotice = true, successNotice = false): Promise<ResponseData> {
    return this.request({
      url,
      method: 'delete',
      data,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      errNotice,
      successNotice
    });
  }
}

export default Request;
